<template>
  <div class="history">
    <div class="bg"></div>
    <div class="history_box_outer w1400">
      <div class="history_box">
        <div class="ball">
          <div class="ball_in"></div>
        </div>
        <swiper
          ref="year_swiper"
          class="year_swiper"
          @transitionStart="onYearSwiperRoll();"
          :options="historySwiperOption">
            <swiper-slide
              class="year_item"
              v-for="(item, i) in historySwiper"
              :key="`historySwiper${i}_${item}`">
                <h2 class="year">
                  {{item.year}}
                </h2>
            </swiper-slide>
        </swiper>
      </div>
    </div>
    <div class="history_text_outer">
      <div class="w1400">
        <img src="../../../assets/img/about/history/logo.svg" class="logo" alt="">
        <div class="controls">
          <div class="control to_left">
            <img src="@/assets/img/public/03_button/02_Button_2.svg" alt="">
          </div>
          <div class="control to_right">
            <img src="@/assets/img/public/03_button/02_Button_2.svg" alt="">
          </div>
        </div>
        <div class="history_text">
          <div
            class="text_item txt-white"
            v-for="(item, i) in historySwiper[nowIndex].history"
            :key="`historyText${i}`">
              <small>
                {{item.date}}
              </small>
              <h6>
                {{item.text}}
              </h6>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { historySwiper } from '@/utils/aboutPageData';

export default {
  name: 'History',
  components: {
  },
  data() {
    return {
      historySwiper,
      nowIndex: 0,
      historySwiperOption: {
        loop: false,
        slidesPerView: 3,
        spaceBetween: 0,
        allowTouchMove: true,
        autoplay: false,
        centeredSlides: true,
        slideToClickedSlide: true,
        navigation: {
          nextEl: '.history_text_outer .to_right',
          prevEl: '.history_text_outer .to_left',
        },
        // breakpoints: {
        //   1023: {
        //     allowTouchMove: true,
        //   },
        // },
      },
    };
  },
  computed: {
    yearSwiper() {
      return this.$refs.year_swiper.swiper;
    },
  },
  methods: {
    onYearSwiperRoll() {
      this.nowIndex = this.yearSwiper.realIndex;
    },
  },
};
</script>
